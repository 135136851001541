import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import useAuth from '../../../contexts/authContext';
//import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import AuthContext from '../../../contexts/authContext';
import { True } from '../../../stories/components/bootstrap/Alert/AlertUseIsDissmisable.stories';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenido,</div>
			<div className='text-center h4 text-muted mb-5'>Inicie sesión para continuar!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	//const { login, loginWithGoogle, resetPassword } = useContext(AuthContext);
	const { signIn, loginWithGoogle } = useContext(AuthContext)
	// const { signIn } = useAuth()
	// const { user } = useAuth();


	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const handleGoogleLogin = async () => {
		try {
			await loginWithGoogle();
			navigate("/");
		} catch (error) {
		  	console.log('error');
		}
	};

	// const usernameCheck = (username: string) => {
	// 	return !!getUserDataWithUsername(username);
	// };

	// const passwordCheck = (username: string, password: string) => {
	// 	return getUserDataWithUsername(username).password === password;
	// };

	// const formik = useFormik({
	// 	enableReinitialize: true,
	// 	initialValues: {
	// 		loginUsername: USERS.JOHN.username,
	// 		loginPassword: USERS.JOHN.password,
	// 	},
	// 	validate: (values) => {
	// 		const errors: { loginUsername?: string; loginPassword?: string } = {};

	// 		if (!values.loginUsername) {
	// 			errors.loginUsername = 'Required';
	// 		}

	// 		if (!values.loginPassword) {
	// 			errors.loginPassword = 'Required';
	// 		}

	// 		return errors;
	// 	},
	// 	validateOnChange: false,
	// 	onSubmit: (values) => {
	// 		if (usernameCheck(values.loginUsername)) {
	// 			if (passwordCheck(values.loginUsername, values.loginPassword)) {
	// 				if (setUser) {
	// 					setUser(values.loginUsername);
	// 				}

	// 				handleOnClick();
	// 			} else {
	// 				formik.setFieldError('loginPassword', 'Username and password do not match.');
	// 			}
	// 		}
	// 	},
	// });

	const [isLoading, setIsLoading] = useState<boolean>(false);
	// const handleContinue = () => {
	// 	setIsLoading(true);
	// 	setTimeout(() => {
	// 		if (
	// 			!Object.keys(USERS).find(
	// 				(f) => USERS[f].username.toString() === formik.values.loginUsername,
	// 			)
	// 		) {
	// 			formik.setFieldError('loginUsername', 'No such user found in the system.');
	// 		} else {
	// 			setSignInPassword(true);
	// 		}
	// 		setIsLoading(false);
	// 	}, 1000);
	// };

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}
			isBackgrounded={true}
			>
			<Page className='p-0'>
				<div className='h-100 w-100' >

				<div 
					className='row h-100 align-items-center justify-content-center'
					>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
											)}>
										<Logo width={200} isLogin={true} />
									</Link>
								</div>
								{/* <div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
									<div className='col'>
									<Button
									color={darkModeStatus ? 'light' : 'dark'}
									isLight={singUpStatus}
									className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
												</Button>
												</div>
												<div className='col'>
												<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
												</Button>
												</div>
												</div>
											</div> */}

								<LoginHeader isNewUser={singUpStatus} />

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
									<div className='col-12'>
									<strong>Username:</strong> {USERS.JOHN.username}
									</div>
									<div className='col-12'>
									<strong>Password:</strong> {USERS.JOHN.password}
									</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
														/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Su correo o usuario'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														// value={formik.values.loginUsername}
														// isTouched={formik.touched.loginUsername}
														// invalidFeedback={
														// 	formik.errors.loginUsername
														// }
														// isValid={formik.isValid}
														// onChange={formik.handleChange}
														// onBlur={formik.handleBlur}
														// onFocus={() => {
														// 	formik.setErrors({});
														// }}
														/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hola, {/*{formik.values.loginUsername}.*/}
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Contraseña'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														// value={formik.values.loginPassword}
														// isTouched={formik.touched.loginPassword}
														// invalidFeedback={
														// 	// formik.errors.loginPassword
														// }
														validFeedback='Looks good!'
														// isValid={formik.isValid}
														// onChange={formik.handleChange}
														// onBlur={formik.handleBlur}
														/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														// isDisable={!formik.values.loginUsername}
														// onClick={handleContinue}
													>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
															)}
														Continuar
													</Button>
												) : (
													<Button
													color='warning'
													className='w-100 py-3'
													//onClick={formik.handleSubmit}
													>
														Iniciar Sesión
													</Button>
												)}
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												O
											</div>
											{/* <div className='col-12 mt-3'>
												<Button
												isOutline
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												icon='CustomApple'
												onClick={handleOnClick}>
												Sign in with Apple
												</Button>
											</div> */}
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleGoogleLogin}>
													Iniciar Sesión con Google
												</Button>
											</div>
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
