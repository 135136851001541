import { createContext, useContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { User, Auth, UserCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase'

export interface IAuthContextProps {
	auth: Auth
    user: User | null
    signIn: (email: string, password: string) => Promise<UserCredential>
    signUp: (email: string, password: string) => Promise<UserCredential>
    sendPasswordResetEmail?: (email: string) => Promise<void>
	loginWithGoogle: () => Promise<UserCredential>
	logout: () => Promise<void>

}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export const useAuth = (): IAuthContextProps => useContext(AuthContext)

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User | null>(null);

	const signUp = (email: string, password: string): Promise<UserCredential> => {
		return createUserWithEmailAndPassword(auth, email, password);
	};

	const signIn = (email: string, password: string): Promise<UserCredential> => {
		return signInWithEmailAndPassword(auth, email, password);
	};

	const loginWithGoogle = (): Promise<UserCredential> => {
		const googleProvider = new GoogleAuthProvider();
		return signInWithPopup(auth, googleProvider);
	};

	const logout = () => signOut(auth);

	const resetPassword = (email: string): Promise<void> => sendPasswordResetEmail(auth, email);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setUser(user);
		})
		return () => unsubscribe();
	}, [])

	// useEffect(() => {
	// 	localStorage.setItem('facit_authUsername', user);
	// }, [user]);

	// useEffect(() => {
	// 	if (user !== '') {
	// 		setUserData(getUserDataWithUsername(user));
	// 	} else {
	// 		setUserData({});
	// 	}
	// }, [user]);

	//const login = (email, password) => signInWithEmailAndPassword(auth, email, password)
	
	// const value = useMemo(
	// 	() => ({
	// 		signup,
	// 		login,
	// 		user,
	// 		setUser,
	// 		logout,
	// 		loginWithGoogle,
	// 		resetPassword
	// 	}),
	// 	[user],
	// );
	const values = {
		signUp,
		user,
		signIn,
		resetPassword,
		auth,
		loginWithGoogle,
		logout
	}
	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
