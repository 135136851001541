import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomBackground = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		style={{
			margin: 'auto',
			background: '#f1f2f3',
			display: 'block',
			zIndex: 1,
			position: 'relative',
			shapeRendering: 'auto',
		}}
		width='1em'
		height='1em'
		preserveAspectRatio='xMidYMid'
		viewBox='0 0 1920 922'
		className='svg-icon'
		{...props}>
		<g>
			<linearGradient id='custom-background_svg__a' x1={0} x2={1} y1={0} y2={0}>
				<stop stopColor='#009b78' offset={0} />
				<stop stopColor='#80cdbc' offset={1} />
			</linearGradient>
		</g>
	</svg>
);
export default SvgCustomBackground;
