import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomChile = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 6 4'
		fill='#fff'
		className='svg-icon'
		{...props}>
		<path d='M0 0h6v4H0z' />
		<path fill='#0039a6' d='M0 2V0h2v3z' />
		<path fill='#d72b1f' d='M0 2h6v2H0z' />
		<path d='m1 .5.294.904-.77-.558h.952l-.77.559z' />
	</svg>
);
export default SvgCustomChile;
