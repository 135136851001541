import React, { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { useAuth } from '../../contexts/authContext';

const User = () => {
	const { logout, user } = useAuth()
	const navigate = useNavigate();
	const handleLogout = async () => {
		try {
			await logout();
			navigate("/auth/login");
		} catch (error) {
			console.log(error)
		}
	}
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						srcSet={user?.photoURL||''}
						src={user?.photoURL||''}
						alt='Avatar'
						width={128}
						height={128}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center text-white'>
						{/* {`${user?.name} ${user?.surname}`} */}
						{ user?.displayName }
						{/* { user?.emailVerified != null && <Icon icon='Verified' className='ms-1' color='secondary' />} */}
					</div>
					<div className='user-sub-title text-white'>
						{ user?.email == 'ignacio.sanchez@spread.cl' ? 'Full Stack Developer' : 'Colaborador' }
						{/* {user?.position} */}
					</div>
				</div>
			</div>
			<DropdownMenu>
				{/* <DropdownItem>
					<Button
						icon='AccountBox'
						// onClick={() =>
						// 	navigate(
						// 		`../${demoPagesMenu.appointment.subMenu.employeeID.path}/${user?.id}`,
						// 	)
						// }
						>
						Profile
					</Button>
				</DropdownItem> */}
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							// onClick={() =>
							// 	navigate(
							// 		`../${demoPagesMenu.appointment.subMenu.employeeID.path}/${user?.id}`,
							// 		// @ts-ignore
							// 		handleItem(),
							// 	)
							// }
							>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							// onClick={() => {
							// 	if (setUser) {
							// 		setUser('');
							// 	}
							// 	navigate(`../${demoPagesMenu.login.path}`);
							// }}
							>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info' onClick={handleLogout}>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
